<template>
    <div class="choose-containers">
        <div class="choose" @click.stop="choose">
            <img :src="imgUrl"/>
        </div>
        <div class="choose-inner-container">
            <slot></slot>
        </div>
        <div class="disable-container" v-if="disabled"></div>
    </div>
</template>

<script>
  export default {
    name: 'rsck-radio',
    props: {
      checked: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        checkUrl: 'https://api.mengjingloulan.com/storage/wechat/coupon-card/checked.png',
        unCheckUrl: 'https://api.mengjingloulan.com/storage/wechat/coupon-card/un-checked.png',
        itemCheck: this.$props.checked
      }
    },
    watch: {
      checked: {
        handler (newV, oldV) {
          this.itemCheck = newV
        },
        immediate: true
      }
    },
    computed: {
      imgUrl () {
        return this.itemCheck == true ? this.checkUrl : this.unCheckUrl
      }
    },
    methods: {
      choose () {
        this.$emit('onChooseClick')
      },
    }
  }
</script>

<style scoped lang="scss">
    .choose-containers {
        display: flex;
        justify-content: center;
        align-items: last-baseline;
        position: relative;

        .choose {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 9px;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .choose-inner-container {
            flex: 1;
            overflow: hidden;
        }

        .disable-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 999;
        }
    }

</style>